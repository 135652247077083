import {
  Alert,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CardMedia,
  CircularProgress,
  Container,
  Paper,
  Slider,
  Snackbar,
  Typography
} from '@mui/material'
import React, { useCallback, useEffect, useState } from 'react'

import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useForm } from 'react-hook-form'
import moment from 'moment'
import { commerceApi } from 'components/api/apiCommerce'
import Header from '../layot/Header'

export interface ICommerceProduct {
  product_category_id: number
  shop_id: number
  product_name: string
  product_price: number
  client_name: string
  client_phone: number
  loan_term: number
}

export const CommercePage: React.FC = () => {
  const theme = useTheme()
  const matchMD = useMediaQuery(theme.breakpoints.up('md'))
  const [isLoad, setIsLoad] = useState(false)
  const [errors, setErrors] = useState('')

  const { register, handleSubmit, watch, setValue } = useForm<ICommerceProduct>({
    defaultValues: {
      loan_term: 1
    }
  })

  const submitHandler = useCallback((data: ICommerceProduct) => {
    setIsLoad(true)
    const requestData = {
      product_category_id: 3,
      shop_id: 2,
      product_name: 'Apple iPhone 14 Pro Max 1TB Silver',
      product_price: 2000,
      client_name: 'Stenly',
      client_phone: 380990909574,
      loan_term: moment().add(data.loan_term, 'months').diff(moment(), 'days')
    }

    commerceApi
      .postShopRequest(requestData)
      .then(res => window.location.replace(res.data.url))
      .catch((err: any) => setErrors(err.response.data.toString()))
      .finally(() => setIsLoad(false))
  }, [])

  useEffect(() => {
    register('loan_term')
  }, [register])

  const watchLoanTerm = watch('loan_term')

  return (
    <Box sx={{ minHeight: '100vh' }}>
      <Header />
      <Container maxWidth="md" sx={{ pt: 3, pb: 3 }}>
        <Paper elevation={8} sx={{ p: 2 }}>
          <Typography variant={matchMD ? 'h2' : 'h4'} align="center" mb={4}>
            Buy on credit
          </Typography>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' }, gap: 2 }}>
            <Card elevation={0} sx={{ flex: 1 }}>
              <CardHeader
                title="Samsung Galaxy S6 Edge"
                titleTypographyProps={{ align: 'center' }}
              />
              <CardMedia
                component="img"
                alt="img"
                image="https://i.allo.ua/media/catalog/product/cache/1/image/710x600/602f0fa2c1f0d1ba5e241f914e856ff9/e/d/edge_white_s6_edge_00_sm-g925f_standard_origin_1.jpg"
                height="400px"
                sx={{ objectFit: 'contain' }}
              />
              <CardContent>
                <Typography variant="subtitle1">
                  Product description: Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                  Ipsum, officia?
                </Typography>
              </CardContent>
            </Card>
            <Box sx={{ flex: 1, p: 2 }}>
              <Typography variant="h5" align="center" mb={2}>
                Loan term:
              </Typography>
              <Box
                onSubmit={handleSubmit(submitHandler)}
                component="form"
                sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}
              >
                <Typography variant="h6">{`${watchLoanTerm} months`}</Typography>
                <Slider
                  defaultValue={1}
                  onChange={(_, value) => {
                    if (typeof value === 'number') setValue('loan_term', value)
                  }}
                  min={1}
                  max={6}
                  color="primary"
                  sx={{
                    '& .MuiSlider-rail': {
                      bgcolor: `${theme.palette.primary.main}!important`,
                      height: 'inherit !important'
                    }
                  }}
                />
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button type="submit" variant="contained">
                    Buy in credit
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Paper>
      </Container>
      <Snackbar open={!!errors} autoHideDuration={6000} onClose={() => setErrors('')}>
        <Alert severity="error" variant="filled">
          {errors}
        </Alert>
      </Snackbar>
      <Backdrop open={isLoad} sx={{ color: '#ffffff', zIndex: theme => theme.zIndex.drawer + 1 }}>
        <CircularProgress color="inherit" size={80} />
      </Backdrop>
    </Box>
  )
}
