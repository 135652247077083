import React, {FC, useRef, useState} from 'react'
import {
  Box,
  Button,
  ClickAwayListener,
  Grow,
  MenuItem as MuiMenuItem,
  MenuList,
  Paper,
  Popper,
  Stack,
} from '@mui/material'
import styled from "styled-components";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const LanguageMenu: FC = () => {
  const [language, setLanguage] = useState('en');

  const [showGoogleTranslate, setShowGoogleTranslate] = useState(false)
  const [open, setOpen] = useState(false)
  const anchorRef = useRef<HTMLButtonElement>(null)

  const handleToggle = () => {
    setOpen(open => !open)
  }

  const handleClose = (event: Event | React.SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) return
    setShowGoogleTranslate(false)
    setOpen(false)
  }

  const handleListKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault()
      setOpen(false)
    }
    if (event.key === 'Escape') setOpen(false)
  }

  return (
      <>
        <img className="language-icon" src="/img/icons/Earth.svg" alt=""/>

        <Stack direction="row" spacing={2}>
          <div>
            <Button
                ref={anchorRef}
                id="lang-btn"
                sx={{
                  padding: '6px 20px',
                }}
                aria-controls={open ? 'lang-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleToggle}
            >
              {language}

              {
                open
                  ? <ArrowDropUpIcon onClick={(e) => {
                      e.stopPropagation()
                      handleToggle()
                    }} />
                  : <ArrowDropDownIcon onClick={(e) => {
                      e.stopPropagation()
                      handleToggle()
                    }} />
              }
            </Button>
            <Popper
                open={open}
                anchorEl={anchorRef.current}
                role="popper"
                placement="auto"
                transition
                disablePortal
                keepMounted
            >
              {({ TransitionProps, placement }) => (
                  <Grow
                      {...TransitionProps}
                      style={{ transformOrigin: placement === 'bottom-start' ? 'left top' : 'left bottom' }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={handleClose}>
                        <MenuList
                            autoFocusItem={open}
                            id="lang-menu"
                            aria-labelledby="lang-btn"
                            onKeyDown={handleListKeyDown}
                        >
                          <MenuItem
                              onClick={e => {
                                setLanguage('en')
                                handleClose(e)
                              }}
                          >
                            EN
                          </MenuItem>
                          <MenuItem
                              onClick={e => {
                                setLanguage('pl')
                                handleClose(e)
                              }}
                          >
                            PL
                          </MenuItem>
                          <MenuItem
                              onClick={e => {
                                setLanguage('es')
                                handleClose(e)
                              }}
                          >
                            ES
                          </MenuItem>
                          <MenuItem
                              sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '15px',
                                alignItems: 'start'
                              }}
                              onClick={() => {
                                setShowGoogleTranslate(true)
                                new google.translate.TranslateElement({ pageLanguage: 'en' }, 'google_translate_element')
                              }}
                          >
                            GoogleTranslate

                            <Box
                                sx={{
                                  display: (showGoogleTranslate ? 'block' : 'none')
                                }}
                                id='google_translate_element'
                                onClick={e => e.stopPropagation()}
                            />
                          </MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
              )}
            </Popper>
          </div>
        </Stack>
      </>
  )
}

const MenuItem = styled(MuiMenuItem)`
  color: black!important;
  
  & * {
    color: black!important;
  }
`

export default LanguageMenu
